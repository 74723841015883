

















































































import Vue from 'vue';
import LocStorage from 'shared/utils/localStorage';
import { Validators } from 'shared/utils/validator';
import { AuthTypes } from 'shared/store/modules/auth/types';

export default Vue.extend({
  name: 'AppAuthLogin',

  props: {
    changeForm: Function,
  },

  data() {
    return {
      remember: true,
      model: {
        username: '',
        password: '',
      },
    };
  },

  mounted() {
    const credentials = LocStorage.getItem('cred');

    if (credentials) {
      this.model = credentials;
    }
  },

  methods: {
    async onSubmit() {
      this.model.username = this.model.username.toLowerCase();
      try {
        await this.$store.dispatch(AuthTypes.actions.LOGIN, this.model);
        if (this.remember) {
          LocStorage.setItem('cred', this.model);
        } else {
          LocStorage.removeItem('cred');
        }
        this.$store.dispatch(AuthTypes.actions.GET_USER_SETTINGS);
        this.$dialog.close();
      } catch (error) {
        this.$noty.error({ text: 'Неверный логин или пароль' });
        console.error(error);
      }
    },

    validationRules(): ValidationConfig {
      return {
        username: [ Validators.required, Validators.email ],
        password: [ Validators.required ],
      };
    },
  },
});
