import isEmail from 'validator/lib/isEmail';
import isInt from 'validator/lib/isInt';
import omit from 'lodash/omit';

export default class Validators {
  public static required(value: any): ValidationError {
    if (
      (Array.isArray(value) && value.length) ||
      (typeof value === 'string' && value.length) ||
      (typeof value === 'number' && !isNaN(value)) ||
      value === true
    ) {
      return null;
    }

    if (typeof value === 'object') {
      // tslint:disable-next-line: forin
      for (const _key in value) {
        return null;
      }
    }

    return 'Обязательное поле';
  }

  public static phone(value: string): ValidationError {
    if (!/^(\+7|8)[0-9]{10}$/.test(value)) {
      return 'Некорректный номер телефона';
    }

    return null;
  }

  public static email(value: string): ValidationError {
    if (value !== '' && !isEmail(value)) {
      return 'Некорректный email';
    }

    return null;
  }

  public static regex(regex: RegExp, errorMessage: string): ValidationFn {
    return (value: string): ValidationError => {
      if (value && !regex.test(value)) {
        return errorMessage;
      }

      return null;
    };
  }

  public static eq(expected: any, errorMessage: string): ValidationFn {
    return (value: any): ValidationError => {
      if (value !== expected) {
        return errorMessage;
      }

      return null;
    };
  }

  public static notEq(notExpected: any, errorMessage: string): ValidationFn {
    return (value): ValidationError => {
      if (value === notExpected) {
        return errorMessage;
      }

      return null;
    };
  }

  public static isInt(options: {
    message: string;
    min?: number;
    max?: number;
  }): ValidationFn {
    return (value: string | number): ValidationError => {
      if (typeof value === 'number') {
        value = value.toString();
      }

      if (!isInt(value, omit(options, 'message'))) {
        return options.message;
      }

      return null;
    };
  }

  public static min(
    minLength: number,
    errorMessage: string = 'Поле слишком короткое'
  ): ValidationFn {
    return (value: string | any[] | number): ValidationError => {
      if (
        (typeof value === 'string' || Array.isArray(value)) &&
        value.length < minLength
      ) {
        return errorMessage;
      }

      // const parsedValue = parseFloat(value as string);

      // if (!isNaN(parsedValue) && typeof parsedValue === 'number' && parsedValue < minLength) {
      //   return errorMessage;
      // }

      return null;
    };
  }

  public static max(
    maxLength: number,
    errorMessage: string = 'Поле слишком длинное'
  ): ValidationFn {
    return (value: string | any[] | number): ValidationError => {
      if (
        (typeof value === 'string' || Array.isArray(value)) &&
        value.length > maxLength
      ) {
        return errorMessage;
      }

      /* const parsedValue = parseFloat(value as string);

      if (!isNaN(parsedValue) && typeof parsedValue === 'number' && parsedValue > maxLength) {
        return errorMessage;
      } */

      return null;
    };
  }

  public static maxValue(maxValue: number, errorMessage: string): ValidationFn {
    return (value: string | any[] | number): ValidationError => {
      const parsedValue = parseFloat(value as string);

      if (
        !isNaN(parsedValue) &&
        typeof parsedValue === 'number' &&
        parsedValue > maxValue
      ) {
        return errorMessage;
      }

      return null;
    };
  }

  public static date(errorMessage: string = 'Невалидная дата'): ValidationFn {
    return (dateString: string): ValidationError => {
      if (!/\d{4}-\d{2}-\d{2}/g.test(dateString)) {
        return errorMessage;
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [ year, month, day ] = dateString
        .split('-')
        .map((v: string): number => parseInt(v, 10));

      if (month > 12 || month < 1 || day > 31 || day < 1) {
        return errorMessage;
      }

      return null;
    };
  }

  public static positiveNumber(value: string | number): ValidationError {
    if (typeof value === 'string') {
      value = parseFloat(value);
    }

    if (isNaN(value) || value < 1) {
      return 'Значение должно быть положительным числом';
    }

    return null;
  }

  public static vin(value: string): ValidationError {
    if (value === '') {
      return null;
    }
    const message = 'Введите корректный VIN';
    const vin = value.toLowerCase();
    if (!/^[a-hj-npr-z0-9]{8}[0-9xX][a-hj-npr-z0-9]{8}$/.test(vin)) {
      return message;
    }

    const transliterationTable: any = {
      0: 0,
      1: 1,
      2: 2,
      3: 3,
      4: 4,
      5: 5,
      6: 6,
      7: 7,
      8: 8,
      9: 9,
      a: 1,
      b: 2,
      c: 3,
      d: 4,
      e: 5,
      f: 6,
      g: 7,
      h: 8,
      j: 1,
      k: 2,
      l: 3,
      m: 4,
      n: 5,
      p: 7,
      r: 9,
      s: 2,
      t: 3,
      u: 4,
      v: 5,
      w: 6,
      x: 7,
      y: 8,
      z: 9,
    };

    const weightsTable = [ 8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2 ];
    let sum = 0;

    for (let i = 0; i < vin.length; ++i) {
      sum += transliterationTable[vin.charAt(i)] * weightsTable[i];
    }

    const mod = sum % 11;

    console.log(vin.charAt(8), mod, mod.toString());

    const res = mod === 10 ? vin.charAt(8) === 'x' : vin.charAt(8) === mod.toString();
    if (!res) {
      return message;
    }
    return null;
  }

  public static gosAutoNum(value: string): ValidationError {
    // транспорт/такси: /^[АВЕКМНОРСТУХ]{2}\d{3}(?<!000)\d{2,3}$/ui
    // прицепы: /^[АВЕКМНОРСТУХ]{2}\d{4}(?<!0000)\d{2,3}$/ui
    // мото, c/х техника: /^\d{4}(?<!0000)[АВЕКМНОРСТУХ]{2}\d{2,3}$/ui
    // транзит: /^[АВЕКМНОРСТУХ]{2}\d{3}(?<!000)[АВЕКМНОРСТУХ]\d{2,3}$/ui
    // выездные: /^Т[АВЕКМНОРСТУХ]{2}\d{3}(?<!000)\d{2,3}$/ui

    // if (!/^[АВЕКМНОРСТУХ]\d{3}(?<!000)[АВЕКМНОРСТУХ]{2}\d{2,3}$/ui.test(value)) {
    //   return 'Некорректный гос. номер';
    // }

    if (!/^[АВЕКМНОРСТУХ]\d{3}[АВЕКМНОРСТУХ]{2}\d{2,3}$/.test(value) || value.slice(-3) === '000') {
      return 'Некорректный гос. номер';
    }

    return null;
  }
}
