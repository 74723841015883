import isEmail from 'validator/lib/isEmail';
import isInt from 'validator/lib/isInt';
import omit from 'lodash/omit';

export default class Validators {
  public static required(value: any): ValidationError {
    if (
      (Array.isArray(value) && value.length) ||
      (typeof value === 'string' && value.length) ||
      (typeof value === 'number' && !isNaN(value)) ||
      value === true
    ) {
      return null;
    }

    if (typeof value === 'object') {
      // tslint:disable-next-line: forin
      for (const _key in value) {
        return null;
      }
    }

    return 'Обязательное поле';
  }

  public static phone(value: string): ValidationError {
    if (!/^(\+7|8)[0-9]{10}$/.test(value)) {
      return 'Некорректный номер телефона';
    }

    return null;
  }

  public static email(value: string): ValidationError {
    if (value !== '' && !isEmail(value)) {
      return 'Некорректный email';
    }

    return null;
  }

  public static regex(regex: RegExp, errorMessage: string): ValidationFn {
    return (value: string): ValidationError => {
      if (value && !regex.test(value)) {
        return errorMessage;
      }

      return null;
    };
  }

  public static eq(expected: any, errorMessage: string): ValidationFn {
    return (value: any): ValidationError => {
      if (value !== expected) {
        return errorMessage;
      }

      return null;
    };
  }

  public static notEq(notExpected: any, errorMessage: string): ValidationFn {
    return (value): ValidationError => {
      if (value === notExpected) {
        return errorMessage;
      }

      return null;
    };
  }

  public static isInt(options: {
    message: string;
    min?: number;
    max?: number;
  }): ValidationFn {
    return (value: string | number): ValidationError => {
      if (typeof value === 'number') {
        value = value.toString();
      }

      if (!isInt(value, omit(options, 'message'))) {
        return options.message;
      }

      return null;
    };
  }

  public static min(
    minLength: number,
    errorMessage: string = 'Поле слишком короткое'
  ): ValidationFn {
    return (value: string | any[] | number): ValidationError => {
      if (
        (typeof value === 'string' || Array.isArray(value)) &&
        value.length < minLength
      ) {
        return errorMessage;
      }

      // const parsedValue = parseFloat(value as string);

      // if (!isNaN(parsedValue) && typeof parsedValue === 'number' && parsedValue < minLength) {
      //   return errorMessage;
      // }

      return null;
    };
  }

  public static max(
    maxLength: number,
    errorMessage: string = 'Поле слишком длинное'
  ): ValidationFn {
    return (value: string | any[] | number): ValidationError => {
      if (
        (typeof value === 'string' || Array.isArray(value)) &&
        value.length > maxLength
      ) {
        return errorMessage;
      }

      /* const parsedValue = parseFloat(value as string);

      if (!isNaN(parsedValue) && typeof parsedValue === 'number' && parsedValue > maxLength) {
        return errorMessage;
      } */

      return null;
    };
  }

  public static maxValue(maxValue: number, errorMessage: string): ValidationFn {
    return (value: string | any[] | number): ValidationError => {
      const parsedValue = parseFloat(value as string);

      if (
        !isNaN(parsedValue) &&
        typeof parsedValue === 'number' &&
        parsedValue > maxValue
      ) {
        return errorMessage;
      }

      return null;
    };
  }

  public static date(errorMessage: string = 'Невалидная дата'): ValidationFn {
    return (dateString: string): ValidationError => {
      if (!/\d{4}-\d{2}-\d{2}/g.test(dateString)) {
        return errorMessage;
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [ year, month, day ] = dateString
        .split('-')
        .map((v: string): number => parseInt(v, 10));

      if (month > 12 || month < 1 || day > 31 || day < 1) {
        return errorMessage;
      }

      return null;
    };
  }

  public static positiveNumber(value: string | number): ValidationError {
    if (typeof value === 'string') {
      value = parseFloat(value);
    }

    if (isNaN(value) || value < 1) {
      return 'Значение должно быть положительным числом';
    }

    return null;
  }
}
