





































































import Vue from 'vue';
import Component from 'vue-class-component';
import Axios, { Canceler, AxiosRequestConfig } from 'axios';
import throttle from 'lodash/throttle';
import noop from 'lodash/noop';
// import VueHighlighter from 'vue-highlight-words';

interface DadataItem {
  value: string;
  unrestricted_value?: string;
  data?: any;
}

interface DadataResponse {
  suggestions: DadataItem[];
}

// const DADATA_TOKEN = '05c00220a7232bd094fadc0b5a1ab6af62f4e41a';
// const DADATA_ENDPOINT = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address';
const DADATA_ENDPOINT = 'https://api.realtycloud.ru/dadata/suggest';
const DADATA_COMPANY_ENDPOINT = 'https://api.realtycloud.ru/dadata/suggest_parties';

let cancel: Canceler;

@Component({
  name: 'AppDadata',

  // components: { VueHighlighter },

  props: {
    suggestionDisabled: {
      type: Boolean,
      default: false,
    },
    minLength: {
      type: Number,
      default: 2,
    },
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Введите адрес или кадастровый номер',
    },
    label: {
      type: String,
      default: '',
    },
    searchBy: {
      type: String,
      default: 'objects',
    },
    type: {
      type: String,
      default: 'text',
    },
    showInn: {
      type: Boolean,
      default: false,
    },
    size: String,
  },
})
export default class AppDadata extends Vue {
  // props
  suggestionDisabled: boolean;
  minLength: number;
  value: string;
  placeholder: string;
  label: string;
  searchBy: string;
  size: string;
  showInn: boolean;

  // data
  query: string = this.value;
  savedQuery: string = '';
  visible: boolean = false;
  inputFocused: boolean = false;
  highlightIndex: number = -1;
  suggestions: DadataItem[] = [];

  get dadataEndpoint() {
    return this.searchBy === 'company'
      ? DADATA_COMPANY_ENDPOINT
      : DADATA_ENDPOINT;
  }

  get nativeValueInput(): string {
    return this.value ? this.value : '';
  }

  get isVisible(): boolean {
    return (
      !this.suggestionDisabled &&
      this.suggestions &&
      this.suggestions.length !== 0 &&
      this.visible &&
      this.inputFocused &&
      this.value.length > this.minLength
    );
  }

  get isFirstSuggestionSelectable(): boolean {
    return this.suggestions.length === 1 && this.searchBy === 'company';
  }

  get highlightWords(): string[] {
    const excludeHighlightWards = [
      'г',
      'респ',
      'ул',
      'р-н',
      'пр-кт',
      'село',
      'деревня',
      'поселок',
      'пр-д',
      'пл',
      'к',
      'кв',
      'обл',
      'д',
    ];

    const words = this.savedQuery.replace(/,/g, '').split(' ');

    return words.filter((word) => {
      return excludeHighlightWards.indexOf(word) === -1;
    });
  }

  animationEnter(el: HTMLDivElement) {
    el.style.height = `${el.scrollHeight}px`;
  }

  animationAfterEnter(el: HTMLDivElement) {
    el.style.height = '';
  }

  onSearch(query: string) {
    // const target = event.target as HTMLInputElement;
    // const query = target.value;

    /* this.query = */ this.savedQuery = query;
    this.highlightIndex = -1;
    this.visible = true;
    this.$emit('input', query);

    if (cancel) {
      cancel();
    }

    if (!this.suggestionDisabled && query.length >= 3) {
      this.requestSuggestionsData(query);
    }
  }

  requestSuggestionsData = throttle(function(this: any, query: any) {
    const config: AxiosRequestConfig = {
      baseURL: this.dadataEndpoint,
      params: {
        query,
        count: 8,
      },

      // headers: {
      //   Authorization: `Token ${DADATA_TOKEN}`,
      // },

      cancelToken: new Axios.CancelToken((c) => {
        cancel = c;
      }),
    };

    this.$api.request(config).then((data: DadataItem[]) => {
      this.suggestions = data;
      this.$emit('suggestions', data);
    })
      .catch(noop);
    // Axios.get<DadataResponse>(DADATA_ENDPOINT, config)
    //   .then((response) => response.data.suggestions)
    //   .then((data: DadataItem[]) => {
    //     this.suggestions = data;
    //     this.$emit('suggestions', data);
    //   })
    //   .catch(noop);
  }, 1000);

  onKeyUp(): void {
    if (!this.isVisible) {
      return;
    }

    let newIndex = this.highlightIndex - 1;
    let value: string;

    if (newIndex < -1) {
      newIndex = this.suggestions.length - 1;
    }

    if (newIndex === -1) {
      this.query = value = this.savedQuery;
    } else {
      this.query = value = this.suggestions[newIndex].value;
    }

    this.$emit('input', value);
    this.highlightIndex = newIndex;
  }

  onKeyDown(): void {
    if (!this.isVisible) {
      return;
    }

    let newIndex = this.highlightIndex + 1;
    let value: string;

    if (newIndex >= this.suggestions.length) {
      newIndex = -1;
      this.query = value = this.savedQuery;
    } else {
      this.query = value = this.suggestions[newIndex].value;
    }

    this.$emit('input', value);
    this.highlightIndex = newIndex;
  }

  onSelect(index: number) {
    let event = 'submit';
    let value = '';

    if (index >= 0 && index < this.suggestions.length) {
      event = 'select';

      value = this.query = this.savedQuery = this.showInn ? this.suggestions[index].data.inn : this.suggestions[index].value + ' ';

      this.$emit('input', value);
    }

    this.$emit(event, value, this.suggestions[index]);
    this.visible = false;
    this.highlightIndex = -1;

    if (value) {
      this.moveCursorToEndLine(value.length);
    }
  }

  onFocus(): void {
    this.inputFocused = true;
    this.$emit('focus');
  }

  onBlur(): void {
    this.inputFocused = false;

    // if (this.isFirstSuggestionSelectable) {
    //   this.onSelect(0);
    // }
    this.$emit('blur');
  }

  preventCursorMove(event: KeyboardEvent): void {
    event.preventDefault();
    const target = event.target as HTMLInputElement;

    if (target.selectionStart !== undefined) {
      target.selectionEnd = target.selectionStart = target.value.length;
    }
  }

  preventSubmit(event: KeyboardEvent): void {
    event.preventDefault();
  }

  moveCursorToEndLine(endPosition: number) {
    requestAnimationFrame(() => {
      const inputComponent = this.$refs.input as Vue;
      const input = inputComponent.$el.querySelector('input');

      if (input) {
        input.focus();
        input.selectionStart = input.selectionEnd = endPosition;
      }
    });
  }
}
