import Vue from 'vue';

export default function floatFilter(_Vue: typeof Vue) {
  _Vue.filter('float', (value: string) => {
    const parsedValue = parseFloat(value);
    if (isNaN(parsedValue)) {
      return value;
    }

    return parsedValue.toFixed(2);
  });

  _Vue.filter('formatPassport', (value: string, isPrint: boolean): string => {
    if (isPrint) {
      value = value.slice(0, 4);
    }

    return value.replace(/(\d{2})(\d{2})(\d+)?/, '$1 $2 $3');
  });
}
